<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('core.payments.setup.recurring')" class="payments">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
              <span>{{ $t('payments.Set_up_recurring_payments') }}</span>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <div v-if="financialDetails.payment_mandate_set === false || (financialDetails.payment_mandate_set === true && financialDetails.payment_mandate_valid === false)">
            <div v-if="initialPaymentAvailable === false">
              <CRow class="m-0">
                <CCol cols="12" xl="12" lg="12" md="12" sm="12">
                  <span v-html="$t('payments.Recurring_payments_setup_explainer')"></span>
                </CCol>
                <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0">
                  <CButton color="primary" @click="createMollieInitialPayment()">
                    <span><i class="fas fa-gear mr-1"/>{{$t('payments.Set_up_my_recurring_payments')}}</span>
                  </CButton>
                </CCol>
              </CRow>
              <CRow v-if="errorMessage" class="m-0">
                <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0">
                  <span v-html="errorMessage" class="error"></span>
                </CCol>
              </CRow>
              <CRow class="m-0">
                <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0">
                  <span v-html="$t('payments.Need_help')"></span>
                </CCol>               
                <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0">
                  <CButton color="secondary" @click="openContactCallSchedulePage()">
                    <span><i class="fa-solid fa-calendar-days mr-1"></i>{{$t('common.Schedule_contact_call')}}</span>
                  </CButton>              
                </CCol>
              </CRow>
            </div>
            <div v-else>
              <CRow class="m-0">
                <CCol cols="12" xl="12" lg="12" md="12" sm="12">
                  <span v-html="$t('payments.Click_on_checkout_url_recurring')"></span>
                </CCol>                
              </CRow>
              <CRow class="m-0">
                <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0">
                  <div v-if="initialPaymentCheckoutUrl" class="d-flex align-items-center">
                    <div @click="openInitialPaymentCheckoutUrl()" class="pointer mr-2">
                      <span class="checkout_url">{{initialPaymentCheckoutUrl}}</span>
                    </div>
                    <div>
                      <CButton color="secondary" @click="copyToClipboard()">
                        <span><i class="fas fa-copy mr-1"/>{{$t('payments.Copy_payment_link')}}</span>
                      </CButton>
                    </div>                    
                  </div>                
                </CCol>                
              </CRow>                          
              <CRow class="m-0">
                <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0">
                  <CButton color="secondary" @click="openContactCallSchedulePage()">
                    <span><i class="fa-solid fa-calendar-days mr-1"></i>{{$t('common.Schedule_contact_call')}}</span>
                  </CButton>
                </CCol>
              </CRow>              
            </div>
          </div>
          <div v-else>
            <CRow class="m-0">
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
                <div v-html="$t('payments.Recurring_payments_already_setup')"></div>
              </CCol>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12">
                <CButton color="primary" @click="openDashboardHomePage()">
                  <span><i class="fas fa-house mr-2"/>{{$t('common.Back_to_home')}}</span>
                </CButton>
              </CCol>    
            </CRow>
          </div>
        </CCardBody>
      </CCard>    
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import noPermission from '@/components/common/noPermission.vue';

export default {
  name: 'Setup',
  components: {
    noPermission
  },
  data() {
    return {
      environmentTag: null,
      platformPermissions: [],
      platformPermissionsLoaded: false,
      financialDetails: {
        payment_mandate_set: false
      },
      initialPaymentAvailable: false,
      initialPaymentCheckoutUrl: null,
      paymentIdExternal: null,
      paymentEventTypeTag: null,
      paymentDetails: null,      
      errorMessage: null,      
    }
  },
  methods: {
    getCompanyFinancialDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/payments/financialdetails')
      .then(res => {      
        this.financialDetails = res.data.data;
      })
      .catch(err => {
        console.error(err);
      });      
    },
    getMollieInitialPayment() {
      // Reset the error message
      this.errorMessage = null;
      // Update the license for the company
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/payments/mollie/payment/initial')
      .then(res => {
        let initialPayment = res.data.data;
        // Update the initialPaymentAvailable value
        this.initialPaymentAvailable = initialPayment.initial_payment_available;
        // Update the initialPaymentCheckoutUrl value
        if(initialPayment.initial_payment_checkout_url ) this.initialPaymentCheckoutUrl = initialPayment.initial_payment_checkout_url;
        // Update the paymentIdExternal value
        if(initialPayment.payment_id_external ) this.paymentIdExternal = initialPayment.payment_id_external;
        // Update the paymentEventTypeTag value
        if(initialPayment.payment_event_type_tag ) this.paymentEventTypeTag = initialPayment.payment_event_type_tag;        
        // Update the paymentDetails value
        if(initialPayment.payment_details ) this.paymentDetails = initialPayment.payment_details;      
      })
      .catch(err => {    
        console.error(err);
      });
    },    
    createMollieInitialPayment() {
      // Reset the error message
      this.errorMessage = null;
      // Update the license for the company
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/payments/mollie/payment/initial')
      .then(res => {
        let initialPayment = res.data.data;
        // Update the initialPaymentAvailable value
        this.initialPaymentAvailable = true;
        // Update the initialPaymentCheckoutUrl value
        this.initialPaymentCheckoutUrl = initialPayment.initial_payment_checkout_url;
        // Update the paymentIdExternal value
        this.paymentIdExternal = initialPayment.payment_id_external;
        // Update the paymentEventTypeTag value
        this.paymentEventTypeTag = initialPayment.payment_event_type_tag;        
        // Update the paymentDetails value
        this.paymentDetails = initialPayment.payment_details;        
        // Force update
        this.$forceUpdate();
      })
      .catch(err => {    
        if(err.response.data.code === 'Initial Payment Failed') {          
          this.errorMessage = this.$t('payments.Initial_payment_failed');
        }
      });
    },
    openInitialPaymentCheckoutUrl() {
      window.open(this.initialPaymentCheckoutUrl, '_self');
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err);
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    openDashboardHomePage() {
      if(this.$router.currentRoute.path !== '/')  this.$router.push({path: '/'});
    },
    openContactCallSchedulePage() {
      if(this.environmentTag === 'harryhr') {
        window.open('https://meetings-eu1.hubspot.com/meetings/bas-hagendijk-heijns/onboarding-call', '_blank');
      } else if(this.environmentTag === 'employalty') {
        window.open('https://meetings-eu1.hubspot.com/bas-hagendijk-heijns/employaltyonboardingnl', '_blank');
      }
    },
    copyToClipboard() {
      // Create temporary textarea for copy
      var copy = document.createElement("textarea");
      document.body.appendChild(copy);
      copy.value = this.initialPaymentCheckoutUrl;
      copy.select();
      document.execCommand("copy");
      document.body.removeChild(copy);
      // Show message
      this.$buefy.toast.open({ message: this.$t('common.Copied_to_clipboard'), type: 'is-success', duration: 2000 });
    }       
  },
  mounted: function() {
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    this.getPlatformPermissions();
    this.getCompanyFinancialDetails();
    this.getMollieInitialPayment();
  }
}
</script>
